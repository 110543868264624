import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Ultralyd from "../../components/Ultralyd.js"
import Knap from "../../components/Knap.js"


const UltralydPage = () => (
    <LayoutComponent>
        <h1>Ultralyd</h1>
        <p>I samråd med våre leverandører har vi utvidet vår leveringskompetanse til også å inkludere ultralydstilbehør i form av treningsfantomer, kvalitetskontroll (QA) og vevssimulering.</p>
        <h1>Våre leverandører</h1>
        <Ultralyd />
        <Knap />
    </LayoutComponent>
)
export default UltralydPage